@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');
.App {
  font-family: 'Sarabun', sans-serif;
  overflow: hidden;
}
::-webkit-scrollbar{
  width: 0.3rem;
  height: auto;
}
::-webkit-scrollbar-track{
  background: rgba(235, 235, 235, 0.625);
  border-radius: 15px;
  width: 0.5rem;
}
::-webkit-scrollbar-thumb{
  background: #38afba;
  border-radius: 15px;
  width: 0.5rem;
}


